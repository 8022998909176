import { useGetCurrentUserQuery, useGetUsersQuery } from 'api/users';
import PropTypes from 'prop-types';
import DatePicker from 'components/base-components/DatePicker';
import Input from 'components/base-components/Input';
import Popover from 'components/base-components/Popover';
import { debounce } from 'throttle-debounce';
import SelectBox from 'components/base-components/SelectBox';
import TimePickerComponent from 'components/base-components/TimePicker';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { USERS_STATUS } from 'utils/statuses';
import { CONTROL_BODY_USER } from 'utils/users-role';
import ASSIGNMENT_ROLES from 'utils/assignment-roles';
import { twentyFourHourTimeFormat } from 'utils/date-time-formatter';

const ControlSessionDetails = ({ survey }) => {
  const { t, i18n } = useTranslation();
  const { values } = useFormState();
  const { controlSessionDate } = values;
  const [enableDatePicker, setEnableDatePicker] = useState(null);
  const [query, setQuery] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const auditDate = moment(survey?.auditedAt || new Date()).format('YYYY-MM-DD');
  const auditTime = moment(survey?.auditedAt || new Date()).format('HH:mm');

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const onSearchUser = debounce(500, value => setQuery(value));

  const userParams = {
    q: query,
    status: USERS_STATUS.active,
    'types[]': [CONTROL_BODY_USER],
  };

  const { data } = useGetUsersQuery(userParams);
  const users = data ? data.users : [];

  const userOptions = useMemo(() => {
    return users
      .filter(({ assignments }) => assignments[0].role === ASSIGNMENT_ROLES.auditor)
      .map(user => ({ label: user.name, value: user.id }));
  }, [JSON.stringify(users)]);

  return (
    <div className="organisation-checklist__control-session">
      <div className="organisation-checklist__control-session-header">
        {t('control_body.survey.form.control_session_details')}
      </div>
      <div className="col-12 col-bleed">
        <div className="col-4 col-bleed">
          <Field name="auditorId" initialValue={survey?.auditorId || currentUser.id}>
            {({ input, meta }) => (
              <SelectBox
                onInputChange={onSearchUser}
                size="tiny"
                label={t('control_body.survey.form.auditor_name')}
                isClearable={false}
                isSearchable={true}
                options={userOptions}
                value={userOptions.find(option => option.value === input.value)}
                onChange={e => input.onChange(e.value)}
                touched={!meta.dirtySinceLastSubmit}
                errorMsg={meta?.submitError}
                isDisabled={survey?.auditorId}
              />
            )}
          </Field>
        </div>
        <div className="col-4 col-bleed-y">
          <Field name="controlSessionDate" initialValue={auditDate}>
            {({ input, meta }) => (
              <DatePicker
                className="user__education--form"
                focused={enableDatePicker}
                date={input.value}
                onChange={e => input.onChange(e)}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                onFocusChange={() => setEnableDatePicker(!enableDatePicker)}
                displayFormat="LL"
                label={t('control_body.survey.form.audit_date')}
                navNext="arrowForwardAlt"
                navPrev="arrowBackAlt"
                size="tiny"
                enablePastDates={true}
                futureYearsCount={0}
                disableFutureDates={true}
                locale={i18n.language}
                disabled={survey?.auditedAt}
              />
            )}
          </Field>
        </div>
        <div className="col-4 col-bleed">
          <Field name="controlSessionTime" initialValue={auditTime}>
            {({ input, meta }) => (
              <Popover
                isVisible={isVisible}
                onOutsideClick={() => setIsVisible(false)}
                content={
                  <>
                    <TimePickerComponent
                      className="invoice__time-picker"
                      getTime={input.value && input.value.split(':')[1]}
                      value={input.value}
                      locale="sv-sv"
                      disableClock={true}
                      handleTime={e => input.onChange(e)}
                      header={t('invoice.release.select_time')}
                    />
                  </>
                }
                direction="bottom-left"
                size="large"
              >
                <Input
                  size="tiny"
                  onClick={() => setIsVisible(true)}
                  value={twentyFourHourTimeFormat(`${controlSessionDate} ${input.value}`)}
                  label={t('control_body.survey.form.audit_time')}
                  isClearable={false}
                  error={meta.submitError}
                  disabled={survey?.auditedAt}
                />
              </Popover>
            )}
          </Field>
        </div>
      </div>
      <hr className="organisation-checklist__checkbox-horizontal-line" />
    </div>
  );
};

ControlSessionDetails.defaultProps = {
  survey: null,
};

ControlSessionDetails.propTypes = {
  survey: PropTypes.shape(),
};

export default ControlSessionDetails;
